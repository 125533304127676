<script lang="ts">
  export let propsName: string, propsUsrname: string, propsAvatarUrl: any;
  import Fa from 'svelte-fa';
  import { faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
  import { faYoutube } from '@fortawesome/free-brands-svg-icons';

  const instaCount = 70500;
  const tiktokCount = 93500;
  const youtubeCount = 45200;

  function startCountUpAnimation(
    targetElement,
    startValue,
    endValue,
    duration
  ) {
    const startTime = performance.now();

    function updateValue(currentTime) {
      const elapsedTime = currentTime - startTime;
      if (elapsedTime < duration) {
        const value = Math.floor(
          startValue + (elapsedTime / duration) * (endValue - startValue)
        );
        targetElement.textContent = value.toLocaleString();
        requestAnimationFrame(updateValue);
      } else {
        targetElement.textContent = endValue.toLocaleString();
      }
    }

    requestAnimationFrame(updateValue);
  }

  function formatNumber(num) {
 if (num >= 1000000) {
   return Math.floor(num / 1000000) + 'M';
 }
 if (num >= 1000) {
   let k = Math.floor(num / 1000);
   let decimal = Math.floor((num % 1000) / 100);
   return k + '.' + decimal + 'K';
 }
 return num.toString();
}

  window.addEventListener('load', () => {
    const followersCounter = document.getElementById('followers-counter');
    startCountUpAnimation(
      followersCounter,
      0,
      instaCount + tiktokCount + youtubeCount,
      1200
    );
  });
</script>

<section id="info-profile">
  <img
    src={propsAvatarUrl}
    alt={propsName}
    class="rounded-circle img-thumbnail"
    id="pfp"
  />
  <h4 id="username">{propsUsrname}</h4>
  <p>🐵 <strong>Para parcerias:</strong> parcerias@criascript.dev</p>
</section>

<section style="margin-top: -70px; margin-bottom: 30px;">
  <div class="icon-text">
    <p>Já somos mais de</p>
    <h3>
      <strong style="font-weight: 800; font-size: 40px;" id="followers-counter"
        >0</strong
      > calvos
    </h3>
  </div>
</section>

<section id="counter-container">
  <div class="counter social instagram">
    <a href="https://www.instagram.com/criascript/">
      <div class="icon-text">
        <h3>
          <strong style="font-weight: 800; font-size: 40px;"
            >{formatNumber(instaCount)}</strong
          >
        </h3>
        <p><Fa icon={faInstagram} size="lg" /> Instagram</p>
      </div>
    </a>
  </div>

  <div class="counter social tiktok">
    <a href="https://www.tiktok.com/@criascript">
      <div class="icon-text">
        <h3>
          <strong style="font-weight: 800; font-size: 40px;"
            >{formatNumber(tiktokCount)}</strong
          >
        </h3>
        <p><Fa icon={faTiktok} size="lg" /> Tiktok</p>
      </div>
    </a>
  </div>

  <div class="counter social youtube">
    <a href="https://www.youtube.com/@criascript">
      <div class="icon-text">
        <h3>
          <strong style="font-weight: 800; font-size: 40px;"
            >{formatNumber(youtubeCount)}</strong
          >
        </h3>
        <p><Fa icon={faYoutube} size="lg" /> Youtube</p>
      </div>
    </a>
  </div>
</section>

<style>
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  #info-profile,
  .icon-text {
    animation: fadeIn 0.5s ease-in;
  }
  section {
    max-width: 675px;
    width: auto;
    padding-top: 5rem;
    margin: auto;
    text-align: center;
  }

  section:nth-child(2) {
    width: 90%;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: #1a202c;
  }

  #username {
    padding-top: 1rem;
    font-family: 'Karla', sans-serif;
  }

  #pfp {
    width: 150px;
    height: 150px;
  }

  #counter-container {
    margin-top: -80px;
    display: flex;
    justify-content: space-between;
  }

  .counter {
    background-color: #ffffffc9;
    width: 30%;
    border-radius: 10px;
    font-size: 1rem;
    text-align: center;
    padding: 15px;
    text-decoration: none;
    transition: background-color 0.5s ease;
    color: #1a202c;
    cursor: pointer;
    animation: slide-in 1.2s ease-out;
  }

  @keyframes slide-in {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .icon-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-text p {
    margin: 0;
    padding-top: 10px;
  }

  .social:hover > a {
    color: azure;
  }

  .instagram:hover {
    background: linear-gradient(to right, #833ab4, #fd1d1d, #fcb045);
  }

  .tiktok:hover {
    background: linear-gradient(to right, #fc354c, #0abfbc);
  }

  .youtube:hover {
    background: linear-gradient(to right, #e52d27, #b31217);
  }

  #followers-counter {
    animation: count-up 2s ease-out;
  }

  @keyframes count-up {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Adicione regras de mídia para telas menores */
  @media (max-width: 768px) {
    #counter-container {
      flex-direction: column;
      align-items: center;
    }

    .counter {
      width: 80%; /* Ajuste o tamanho conforme necessário */
      margin-bottom: 20px;
    }
  }
</style>


