<script lang="ts">
  import Fa from 'svelte-fa';
  export let propsUrl;
</script>

<div>
  <div id="links">
    <a
      class="link link-rinha"
      href="https://itch.io/jam/cs-ps2"
      target="_blank"
      >Participe do 1º Cria Gamejam!
    </a>
    {#each propsUrl as { name, url, icon }}
      <a class="link" href={url} target="_blank"><Fa {icon} /> {name}</a>
    {/each}
  </div>
  <p class="footer">Feito com preguiça pelo Cria 👍</p>
</div>

<style>
  @keyframes pulse {
    0%,
    100% {
      background-color: transparent;
    }
    50% {
      background-color: red;
      font-weight: 900;
      transform: scale(1.1);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    opacity: 0;
    animation: fadeIn 1s ease forwards;
  }

  #links {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
  }

  .link-rinha {
    animation: pulse 3s ease infinite alternate, fadeIn 1s ease forwards;
    color: whitesmoke;
    position: relative;
    border: solid whitesmoke 2px;
    border-radius: 10px;
    font-size: 1rem;
    text-align: center;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    text-decoration: none;
    transition: 0.2s ease;
    overflow: hidden;
    opacity: 0;
  }
</style>
