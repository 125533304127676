<script lang="ts">
  import Intro from "./Components/Intro.svelte";
  import Links from "./Components/Links.svelte";
  import SvelteSeo from "svelte-seo";
  export let name: string, username: string, avatarUrl: any, url: any;
  window.document.body.classList.toggle("dark");
  
</script>

<SvelteSeo
  description="Mandando a real sobre a bolha dev 👌"
  openGraph={{
    title: `Criascript Links`,
    description: `Mandando a real sobre a bolha dev 👌`,
    type: `website`,
  }}
  twitter={{
    title: `Mandando a real sobre a bolha dev 👌`,
    description: `Criascript Links`,
    site: `@criascript`,
  }}
/>

<Intro propsName={name} propsUsrname={username} propsAvatarUrl={avatarUrl} />
<Links propsUrl={url} />

<style>
</style>
