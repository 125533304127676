import App from './App.svelte';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.css';
import { faGithub, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
const app = new App({
    target: document.body,
    props: {
        name: 'CriaScript',
        username: 'criascript',
        avatarUrl: '/cria.PNG',
        url: [
            {
                name: 'Servidor Discord',
                url: 'https://discord.gg/jMhGXNAUTq',
                icon: faDiscord
            },
            {
                name: 'E o Pix?',
                url: 'https://livepix.gg/criascript',
                icon: faMoneyBill,
            },
            {
                name: 'GitHub',
                url: 'https://github.com/criascript',
                icon: faGithub,
            },
        ],
    },
});
export default app;
